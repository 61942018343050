import { DialogProps, Grid, TextField } from '@material-ui/core';
import { ApiClient } from 'ApiClient';
import { FormDialog, SnackbarContext } from 'components';
import * as React from 'react';
import { RoundRobinStanding } from 'models';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  standings: RoundRobinStanding[] | undefined;
}

export const DeductsDialog: React.FC<Props> = ({
  standings,
  dialogProps,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);
console.log(standings);
  const [loading, setLoading] = React.useState(false);
  const [points, setPoints] = React.useState(0);
  const [teamId, setTeamId] = React.useState(0);
  React.useEffect(() => {
    if (dialogProps.onClose) {
      setPoints(0);
      setTeamId(0); 
    }
  }, [dialogProps]);
  return (
    <FormDialog
      title={`Deduct Points`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Points to deduct"
            type="number"
            fullWidth
            onChange={(e) => {
              const value = e.target.value;
              setPoints(parseInt(value, 10));
            }}
            name="points"
            value={points}
            helperText="Enter the number of points to deduct, e.g. 1 to deduct 1 point, -1 to add 1 point (use arrow keys for minus numbers)"
            required
            inputProps={{ min: -Infinity }}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={standings?.find((s) => s.id === teamId) || null}
            options={standings ?? []}
            getOptionLabel={(option: RoundRobinStanding) =>
              option.teamCompetition.team.name
            }
            onChange={(
              e: React.ChangeEvent<{}>,
              team: RoundRobinStanding | null,
            ) => setTeamId(team?.id ?? 0)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="team"
                variant="standard"
                label="Team"
                inputProps={{
                  ...params.inputProps,
                }}
                required={true}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { 
        points, 
        teamId 
      };

      const response = await ApiClient.post(`round-robin-standings/deduct-points`, data)

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Points saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
